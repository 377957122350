import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ChatBot from 'react-simple-chatbot';

class Review extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      target: '',
      category: '',
    };
  }

  componentWillMount() {
    const { steps } = this.props;
    const { name, target, category } = steps;

    this.setState({ name, target, category });
  }

  render() {
    const { name, target, category } = this.state;
    return (
      <div style={{ width: '100%' }}>
        <h3>Résumé</h3>
        <table>
          <tbody>
            <tr>
              <td>Nom</td>
              <td>{name.value}</td>
            </tr>
            
            {target &&
            <tr>
              <td>Bénéficiaire</td>
              <td>{target.value}</td>
            </tr>}

            <tr>
              <td>Rubrique</td>
              <td>{category.value}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

Review.propTypes = {
  steps: PropTypes.object,
};

Review.defaultProps = {
  steps: undefined,
};

class ChatBotForm extends Component {
  render() {
    return (
      <ChatBot
        steps={[
          {
            id: 'introduction',
            message: 'Bonjour',
            trigger: 'name-question',
          },
          {
            id: 'name-question',
            message: 'Quel est votre nom?',
            trigger: 'name',
          },
          {
            id: 'name',
            user: true,
            trigger: 'welcome',
            placeholder:"Entrez votre nom..."
          },
          {
            id: 'welcome',
            message: 'Bienvenue {previousValue}',
            trigger:'whom',
          },
          {
            id: 'whom',
            message: 'Pour qui devons nous prier ?',
            trigger:'target-message',
          },
          {
            id: 'target-message',
            hideInput:true,
            options: [
                { value: 'me', label: 'pour vous-même', trigger: 'category-question' },
                { value: 'other', label: 'pour un bénéficiaire', trigger: 'target-name-question' },
              ],
          },
          {
            id: 'target-name-question',
            message: 'Comment s\'appelle ce bénéficiaire?',
            trigger: 'target',
          },
          {
            id: 'target',
            user: true,
            trigger: 'category-question',
            placeholder:"Entrez son prénom..."
          },
          {
            id: 'category-question',
            message: "De quoi avez-vous besoin ?",
            trigger: 'category',
          },
          {
            id: 'category',
            options: [
                { value: 'Spiritualité', label: 'Renforcement spirituel', trigger: 'summary' },
                { value: 'Santé', label: 'Guérison d\'une maladie', trigger: 'summary'},
                { value: 'Social', label: 'Soutien social', trigger: 'summary'},
                { value: 'Mission', label: 'Support missionnaire', trigger: 'summary'},
              ],
          },
          {
            id: 'summary',
            message: 'Parfait, récapitulons votre sujet de prière',
            trigger: 'review',
          },
          {
            id: 'review',
            component: <Review />,
            asMessage: true,
            trigger: 'update',
          },
          {
            id: 'update',
            message: 'Voulez-vous modifier quelque-chose?',
            trigger: 'update-question',
          },
          {
            id: 'update-question',
            options: [
              { value: 'yes', label: 'Oui', trigger: 'update-yes' },
              { value: 'no', label: 'Non', trigger: 'contact' },
            ],
          },
          {
            id: 'update-yes',
            message: 'Que souhaiteriez-vous modifier ?',
            trigger: 'update-fields',
          },
          {
            id: 'update-fields',
            options: [
              { value: 'name', label: 'Votre nom', trigger: 'update-name' },
              { value: 'target', label: 'Le nom du bénéficiaire', trigger: 'update-target' },
              { value: 'category', label: 'Le sujet de prière', trigger: 'update-category' },
            ],
          },
          {
            id: 'update-name',
            update: 'name',
            trigger: 'summary',
          },
          {
            id: 'update-target',
            update: 'target',
            trigger: 'summary',
          },
          {
            id: 'update-category',
            update: 'category',
            trigger: 'summary',
          },
          {
            id: 'contact',
            message: 'Un e-mail est requis pour valider votre demande de prière',
            trigger: 'email-question',
          },
          {
            id: 'email-question',
            message: 'Quelle est votre adresse e-mail ?',
            trigger: 'email',
          },
          {
            id: 'email',
            user: true,
            trigger: 'confirmation',
            placeholder:"Entrez adresse e-mail..."
          },
          {
            id: 'confirmation',
            message: 'Une confirmation à été envoyée à l\'adresse {previousValue} !',
            trigger: 'restart-question'
            
          },
          {
            id: 'restart-question',
            message: 'Souhaitez-vous soumettre un nouveau sujet ?',
            trigger: 'restart',
          },
          {
            id: 'restart',
            options: [
              { value: 'yes', label: 'Oui', trigger: 'whom' },
              { value: 'no', label: 'Non', trigger: 'stop' },
            ],
          },
          {
            id: 'stop',
            message: 'Merci, soyez bénis en Jésus-Christ',
            end:true,
          },
        ]}
      />
    );
  }
}

export default ChatBotForm;