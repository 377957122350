import logo from './logo.svg';
import './App.css';
import Chatbot from 'react-simple-chatbot';
import ChatBotForm from './ChatbotForm';

function App() {
  return (
    <div className="App">
      <h1>ChatBot JLRPT</h1>

      <ChatBotForm />
    </div>
  );
}

export default App;
